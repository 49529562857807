<template>

    <div>


       <b-modal
        id="modal-0"
        ref="modal-0"
        title="Editar Férias"
        data-backdrop="static"
        size="xl"
        cancel-disabled
        no-close-on-backdrop
        hide-header-close
        header-class="modal-header
            background-principal
            text-color-principal
            card-item-result
            "
        content-class="modal-content border-rounded-detail"
        dialog-class="p-3"
        title-class="modal-title mx-auto xl"
        >
        <div class="card-body">

            <div class="row">

                <div class="form-group col-lg-4">
                    <label class="form-control-label" for="edicao-anoExercicio"
                        >Ano Exercício
                        </label>
                        <input
                        type="text"
                        class="form-control form-control-border border-width-2"
                        name="anoExercicio"
                        id="edicao-anoExercicio"
                        data-cy="anoExercicio"
                        :class="{
                            'is-invalid': isSaving && $v.ferias.anoExercicio.$error}"
                            v-model="ferias.anoExercicio
"
                        />
                </div>

                <div class="form-group col-lg-4">
                    <label class="form-control-label" for="edicao-anoFerias"
                        >Ano
                        </label>
                        <input
                        type="text"
                        class="form-control form-control-border border-width-2"
                        name="anoFerias"
                        id="edicao-anoFerias"
                        data-cy="anoFerias"
                        :class="{
                            'is-invalid': isSaving && $v.ferias.ano.$error}"
                            v-model="ferias.ano"
                        />
                </div>

                <div class="form-group col-lg-4">
                    <label for="edicao-mesFerias">Mês Férias</label>
                    <v-select
                        class="border-v-select mt-1"
                        id="edicao-mesFerias"
                        v-model="ferias.mes"
                        :options="mes"
                        :reduce="mes => mes.id"
                        label="descricao" >
                        <span slot="no-options">Desculpe, sem opções no momento.</span>
                    </v-select>
                </div>

            </div>
            <div class="row">
                <div class="form-group col-lg-6">
                    <label class="form-control-label" for="edicao-dataInicio"
                    >Data Início</label>
                        <div class="d-flex">
                            <input
                                type="date" data-cy="dataInicio" class="form-control form-control-border border-width-2" id="edicao-dataInicio"
                                placeholder="dd/mm/aaaa"  v-model="ferias.dataInicio"

                                :class="{
                                'is-invalid': isSaving && $v.ferias.dataInicio.$error}"
                            />
                        </div>
                </div>

                <div class="form-group col-lg-6">
                    <label class="form-control-label" for="edicao-data_fim"
                    >Data Fim
                    </label >
                    <div class="d-flex">
                        <input
                            type="date" data-cy="dataFim" class="form-control form-control-border border-width-2" id="edicao-data_fim"
                            placeholder="dd/mm/aaaa" v-model="ferias.dataFim"
                            :class="{'is-invalid': isSaving && $v.ferias.dataFim.$error}"
                        />
                    </div>
                </div>
                <div class="col-sm-6">
            <div class="form-group">
              <label for="ferias-supervidor">Supervisor(a)</label>
              <v-select class="border-v-select mt-1" id="ferias-supervidor" v-model="ferias.supervisorAssinatura"
                    :options="listaSupervisor" :reduce="(listaSupervisor) => listaSupervisor.id" label="nome">
                    <span slot="no-options">Desculpe, sem opções no momento.</span>
              </v-select>
            </div>
          </div>



            </div>



            <!-- <div class="card-footer">
                <div class="float-right">
                    <button @click.prevent="alterarFerias(ferias.feriasId)" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Enviar
                    </button>
                    <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-times"></i> Limpar
                    </button>
                </div>
            </div> -->
        </div>
            <template #modal-footer="{ ok, cancel }">

                    <button @click.prevent="[alterarFerias(ferias.idFerias), ok()]" class="btn text-color-principal background-principal ml-1">
                    <i class="far fa-envelope"></i> Salvar
                    </button>
                    <button @click.prevent="LimparFerias" type="reset" class="btn btn-default">
                    <i class="fas fa-ban"></i> Limpar
                    </button>
                    <b-button
                                class="btn btn-default float-right"
                    @click="[cancel(), apagarCamposFerias()]"
                    >
                    <i class="fa-solid fa-x"></i> Fechar
                    </b-button>
            </template>
        </b-modal>
    </div>

</template>

<script>

import rhService from "@/service/RHService";
import ConsultaFerias from "@/components/rh/ferias/ConsultaFerias.vue";
import { EventBus } from "@/main";

export default {

    components:{
        ConsultaFerias,

    },
    data(){

        return{
            data:{},
            ferias:{

            },
            listaSupervisor: {},


            mes:[

                { id : 1, descricao: "Janeiro" },
                { id : 2, descricao: "Fevereiro" },
                { id : 3, descricao: "Março" },
                { id : 4, descricao: "Abril" },
                { id : 5, descricao: "Maio" },
                { id : 6, descricao: "Junho" },
                { id : 7, descricao: "Julho" },
                { id : 8, descricao: "Agosto" },
                { id : 9, descricao: "Setembro" },
                { id : 10, descricao: "Outubro" },
                { id : 11, descricao: "Novembro" },
                { id : 12, descricao: "Dezembro" },


            ],

            feriasId:{

            },
            submitted: false,
            isSaving: false,
            idFerias:{},

        }

    },

    validations:{

        ferias:{
            anoExercicio:{},
            ano:{},
            mes:{},
            dataInicio:{},
            dataFim:{},
            observacao:{},
        },
    },

    created(){
       //this.alterarFerias(idFerias);
        //this.feriasTeste();

    EventBus.$on("cadastrarFerias", (listener) => {
        this.ferias = listener;
        console.log(this.ferias);
        }),


    EventBus.$on("EnviodeFerias",(listener)=>{
        this.getFeriasPorId(listener)
        console.log(listener)

    });

    this.buscaSupervisor();

    },


    methods:{

        buscaSupervisor() {
        rhService
        .buscaSupervisors()
        .then((response) => {
            this.listaSupervisor = response.data;
        });
    },

    getFeriasPorId(feriasId){
        rhService.getFeriasPorId(feriasId).then((resp)=>{
        this.ferias = resp.data;

        });
    },


    LimparFerias(){
        this.ferias = {};

    },

    apagarCamposFerias(){
        this.ferias = {};
    },

    alterarFerias(){

        // if(this.ferias.anotado==true){
        //     return Toast.fire("Férias não pode ser editada", "", "error");
        // }
        // else {

            rhService.editarFerias(this.ferias).then((resp)=>{
            this.ferias = resp.data;
            this.ferias={};
            EventBus.$emit("AtualizarTabelaFerias");
            Toast.fire("Edição realizada", "", "success");


        }).catch((error) => {
          const errorMessage = error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Falha ao editar férias";
          Toast.fire(errorMessage, "", "error");
        });


        // }

     },


    },




}
</script>
