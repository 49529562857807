import authHeader from "@/service/auth/auth-header";
import timeToken from "@/util/timeToken";
import axios from "axios";
import store from "../store/index.js";
import authService from "./auth/auth.service.js";

export const Versao = 'main';
// export const ApiGateway =
//   Versao === "desenvolvimento"
//     ? "http://172.20.1.15:8010"
//     : "https://gateway.ssp.ma.gov.br";

export const ApiGateway = (() => {
  if (Versao === "desenvolvimento") {
    return "http://172.20.1.32:8010";
  } else if (Versao === "main") {
    return "https://gateway.ssp.ma.gov.br";
  } else {
    return "https://gatewaydev.ssp.ma.gov.br";
  }
})();

const instance = axios.create({
  baseURL: ApiGateway,
});

instance.interceptors.request.use(function (config) {
  const rotasSemRefresh =
    config.url.includes("lista") ||
    config.url.includes("login") ||
    config.url.includes("refresh");

  const rotaRecuperaSenha = config.url.includes("recuperarSenha");

  if (!rotaRecuperaSenha) {
    if (!rotasSemRefresh) {
      // atualiza o token
      // store.dispatch("refreshToken", store.state.auth.user);
      store.dispatch("refreshToken");
      const minutos = 30;
      timeToken.$emit("resetaTimeToken", minutos * 60);
    }

    // adiciona o bearer token
    const token = authHeader().Authorization;
    config.headers.Authorization = token;
  }


  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (
      res.data.erro &&
      res.data.erro.includes("Token expirado") &&
      res.status == 401
    ) {
      // deslogar o usuário
      authService.logout();
      window.location.href = "/login";
    }

    if (res.status == 503) {
      console.log("Serviço indisponível, código 503");
    }

    // if (res.status == 500) {
    //   window.location.href = "/found500";
    // }

    return Promise.reject(error);
  }
);

export default instance;
